import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import { ProjectBySlugQuery } from '../../graphql-types'
import { Content } from '../components/styled'
import styled from 'styled-components'
import { DEFAULT_SIZE, next, prev } from '../Utils'
import MarkdownRenderer from '../components/MarkdownRenderer'

const ProjectTemplate = ({
  data,
  location,
}: {
  data: ProjectBySlugQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const post = get(data, 'contentfulBlogPost')

  console.log(data, 'this is the data')

  return (
    <Layout location={location}>
      <Content>
        <Project>
          <Helmet title={`${data.contentfulProject.title} | ${siteTitle}`} />

          <p className="grey">
            <b>{data.contentfulProject.title}</b>
          </p>
          <p>
            <span className="grey">
              Client <br />
            </span>
            {data.contentfulProject.client &&
              data.contentfulProject.client
                .split(',')
                .map((txt) => <span>{txt}</span>)}
          </p>
          <p>
            <span className="grey">
              Architect <br />
            </span>
            {data.contentfulProject.architect.split(',,').map((txt) => (
              <span>{txt}</span>
            ))}
          </p>
          <p>
            <span className="grey">
              Local Authority <br />
            </span>
            {data.contentfulProject.localAuthority.split(',,').map((txt) => (
              <span>{txt}</span>
            ))}
          </p>
          <p>
            <span className="grey">
              Category
              <br />
            </span>
            {data.contentfulProject.category.map((txt) => (
              <span>{txt}</span>
            ))}
          </p>
          <p>
            <span className="grey">
              Status <br />
            </span>
            {data.contentfulProject.status}
          </p>
          {/* <h1>{data.contentfulFeaturedProject.title}</h1> */}
          {/* <p>{data.contentfulFeaturedProject.description.description}</p> */}
        </Project>
        <div className="image">
          <Img
            style={{ width: '100%', height: 'auto' }}
            alt={''}
            fluid={data.contentfulProject.featuredImage.fluid}
          />

          {data.contentfulProject.description && (
            <div>
              <br />
              <MarkdownRenderer
                className="about-text"
                html={
                  data.contentfulProject.description.childMarkdownRemark.html
                }
              />
            </div>
          )}

          <br />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <div>
              <Link
                to={
                  '/project/' +
                  prev(
                    data.allContentfulProject.edges
                      .sort(
                        (a, b) =>
                          Date.parse(b.node.date) - Date.parse(a.node.date)
                      )
                      .map(({ node }) => node.slug),
                    data.contentfulProject.slug
                  )
                }
              >
                Previous Project
              </Link>
            </div>
            <div>
              <Link
                to={
                  '/project/' +
                  next(
                    data.allContentfulProject.edges
                      .sort(
                        (a, b) =>
                          Date.parse(b.node.date) - Date.parse(a.node.date)
                      )
                      .map(({ node }) => node.slug),
                    data.contentfulProject.slug
                  )
                }
              >
                Next Project
              </Link>
            </div>
          </div>
        </div>
      </Content>

      {/* <div style={{ background: '#fff' }}>
        <Helmet title={`${post.title} | ${siteTitle}`} />
        <div className={heroStyles.hero}>
        <Img
            className={heroStyles.heroImage}
            alt={post.title}
            fluid={post.heroImage.fluid}
          />
        </div>
        <div className="wrapper">
          <h1 className="section-headline">{post.title}</h1>
          <p
            style={{
              display: 'block',
            }}
          >
            {post.publishDate}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </div>
      </div> */}
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulProject {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      architect
      category
      description {
        description
        childMarkdownRemark {
          html
          htmlAst
        }
      }
      date
      title
      localAuthority
      client
      status
      slug
      createdAt
      featuredImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const Project = styled.div`
  /* display: grid;
  grid: 1fr / 1fr 2fr;
  flex-direction: row;
  padding: 1rem;

  p,
  span {
    /* font-size: ${DEFAULT_SIZE}rem; */
  /* } */

  span {
    display: block;
  }

  h1 {
    margin-top: 0;
  }

  .text {
    p {
      margin-top: 0;
    }
  }
`
