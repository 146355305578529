import React from 'react'
import { MarkdownRemark } from '../../graphql-types'
import styled from 'styled-components'

type AST = {
  type: string
  tagName: string
  properties: Record<string, string> & { className?: string[] }
  children: AST[]
  value: string
}

const astToHtml = (ast: AST) => {
  const children = (children: AST[]) =>
    children.map((node) => astToHtml(node)).join('')

  const properties = (properties: AST['properties']) =>
    Object.keys(properties)
      .map((key) =>
        key === `className`
          ? ` class="${properties['className'].join(' ')}"`
          : ` ${key}="${properties[key]}"`
      )
      .join('')

  switch (ast.type) {
    case 'root':
      return `<div>${children(ast.children)}</div>`
    case 'element':
      if (ast.tagName === 'br') return `<br/>`
      if (ast.tagName === 'img') return `<img ${properties(ast.properties)}/>`

      if (ast.tagName === 'p' && ast.children[0].tagName === 'img')
        return `<p ${properties(ast.properties)}>${children(ast.children)}</p>`

      return `<${ast.tagName} class="text-wrap" ${properties(
        ast.properties
      )}>${children(ast.children)}</${ast.tagName}>`
    case 'text':
      return ast.value
    default:
      console.log(`Failed...`)
  }
}

const MarkdownRenderer = ({
  html,
  className,
}: {
  html: string
  className?: string
}) => (
  <Styled>
    <div
      className={className + ' content'}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  </Styled>
)

const Styled = styled.div`
  width: 100% a {
    text-decoration: underline;
  }

  .content > *:first-child {
    padding-top: 0;
    margin-top: 0;
  }

  .content > *:last-child,
  .content > *:last-child > img:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  span,
  p {
    /* padding-right: calc(100% - 700px); */
  }

  img {
    padding: 2rem 0;
    width: 100%;
  }
`

export default MarkdownRenderer
